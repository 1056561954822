import React from 'react';
import './App.css'; // Import the CSS file
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import RouteWrapper from './routes/RouteWrapper';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/conferenza2024/:token" element={<RouteWrapper />} />
            <Route path="/" element={<Navigate replace to="/conferenza2024/placeholderToken" />} /> {/* Ensure this redirection is what you want */}
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
