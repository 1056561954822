const NODE_ENV = process.env.REACT_APP_NODE_ENV || 'development'
const apiUrls = {
	development: 'https://dev-api.confagrisrl.it',
	staging: 'https://dev-api.confagrisrl.it',
	production: 'https://api.confagrisrl.it',
}
const apiUrl = apiUrls[NODE_ENV]

export const validateToken = async (token) => {
	try {
		const response = await fetch(apiUrl + '/event/jwt/forms', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
		})

		if(!response.ok)
			return false
		const responseData = await response.json()
		return responseData?.type
	} catch (error) {
		console.error('Error during form submission:', error)
		return false
	}
}

export const submit = async(token, data) => {
	try {
		const response = await fetch(apiUrl + '/event/form/submit', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		});

		if(response.status === 422) {
			const formErrors = await response.json()
			console.error('FORM validation errors', data, formErrors)
			return formErrors
		}
		if(!response.ok) {
			console.error('Error during form submission:', response, await response.json());
			throw new Error('Network response was not ok');
		}
		return true
	} catch (error) {
		console.error('Error during form submission:', error)
		return false
	}
}
