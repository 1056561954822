import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import logo from '../assets/images/UPA_Logo.png'; // Ensure the path is correct based on your project structure

const Footer = () => {
  return (
    <Container maxWidth="sm">
        <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        borderRadius: '8px',
        backgroundColor: 'white',
        marginTop: 2,
        flexDirection: 'column', // Adjust this if you want the content to be vertically aligned
        }}>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%', // Ensure full width if needed
        }}>
            <img src={logo} alt="Confagricoltura Brescia logo" style={{ marginRight: '16px', height: 'auto' }} />
            <div style={{ color: 'rgb(0, 123, 60)', textAlign: 'center' }}>
            <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>Confagricoltura - Brescia</Typography>
            <Typography variant="h6" component="div">Unione Provinciale Agricoltori</Typography>
            </div>
        </Box>
        </Box>
    </Container>
  );
};

export default Footer;