import React from 'react';
import { Container, Grid, Typography, Box, Divider  } from '@mui/material';

const Header = () => {
  return (
    <>
        <Container maxWidth="sm">
        {/* Apply the background color to the Container or a Box within it */}
            <Box sx={{padding: 2,  backgroundColor:  'rgb(13, 95, 49)', color: 'white', borderRadius: '8px', padding: 2 }}>
                <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="h5" component="h1" textAlign="center">
                    Assemblea Generale 2024
                    </Typography>
                    <Box sx={{ width: '100%', height: '5px', marginTop: 1, backgroundImage: 'linear-gradient(to right, green 33%, white 33%, white 66%, red 66%)' }} />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" component="p" textAlign="center" fontWeight="bold">
                    VENERDI 1 MARZO ORE 17.00
                    </Typography>
                    <Typography variant="body2" component="p" textAlign="center">
                    SALA DISPLAY - BRIXIA FORUM
                    </Typography>
                </Grid>
                </Grid>
            </Box>
        </Container>

        {/* Text Box Section */}
        <Container maxWidth="sm" sx={{ mt: 2 }}>
            <Box sx={{ borderTop: 10, borderColor: 'rgb(13, 95, 49)' , backgroundColor: 'white', borderRadius: '8px' , padding: 2 ,marginBottom: 2 }}>
                <Typography variant="h4" component="p" sx={{ mt: 2, fontWeight: 'bold' }}>
                REGISTRAZIONE PRESENZE
                </Typography>
                <Typography variant="h6" component="p" sx={{ mt: 2 }}>
                ASSEMBLEA GENERALE 2024
                </Typography>
                {/* <Typography variant="body1" component="p" sx={{ mt: 2 }}>
                Compila il modulo per registrare la tua presenza all'Assemblea 2024 di Confagricoltura Brescia.
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" component="p" sx={{ mb: 2 }}>
                * Indica una domanda obbligatoria
                </Typography> */}
            </Box>
        </Container>
    </>
  );
};

export default Header;


